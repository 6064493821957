import React from 'react'
import { Redirect } from 'react-router'
import { language } from '@ksbteam/core/api/DummyTranslate'

const FrontPage = () => {
    let pathnameArray = window.location.pathname.split('/')
    if (!pathnameArray[1].length || pathnameArray.length < 3 || (pathnameArray.length === 3 && !pathnameArray[2].length)) {
        return <Redirect push to={`/${language || 'ru'}/main`}/>
    }
    return null;
}

export default FrontPage;
