import React from 'react';
import {BrowserRouter as Router, Route} from "react-router-dom";
import CssBaseline from '@material-ui/core/CssBaseline';
import {MuiThemeProvider, withStyles} from '@material-ui/core/styles';
import theme from './theme';
import {mainMenu} from '../api/MainMenu';
import {connect} from "react-redux";
import CircularProgress from '@material-ui/core/CircularProgress';
import FrontPage from "./FrontPage";

const styles = () => ({
    progressWrapper: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 300
    }
});



class Page extends React.Component {
    render() {
        const {classes, user} = this.props;
        if (!user) {
            return (
                <div className={classes.progressWrapper}>
                    <CircularProgress size={40}/>
                </div>
            );
        }
        let routerItems = mainMenu.getRouterItems();
        return (
            <Router>
                <MuiThemeProvider theme={theme}>
                    <CssBaseline/>
                    {routerItems.map(item =>
                        <Route key={item.url} path={item.url} component={item.component} exact/>
                    )}
                    <Route key={"*"} component={FrontPage}/>
                </MuiThemeProvider>
            </Router>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.page.User
    };
};

export default connect(mapStateToProps)(withStyles(styles)(Page));
